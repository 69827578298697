<template>
	<div class="dashboard">
		<div class="dashboard__body">
			<div class="boxes">
				<router-link
					:to="{ name: 'ContractHome' }"
					v-if="checkModule({ moduleName: 'Contrato' })"
				>
					<div class="text">
						<v-icon>mdi-file-sign</v-icon>
						<div class="text__title">Legal</div>
						<div class="text__description"></div>
					</div>
				</router-link>

				<router-link
					:to="{ name: 'AgreementHome' }"
					v-if="checkModule({ moduleName: 'abastecimiento' })"
				>
					<div class="text">
						<v-icon>mdi-note-plus</v-icon>
						<div class="text__title">Abastecimiento</div>
						<div class="text__description"></div>
					</div>
				</router-link>

				<router-link
					:to="{ name: 'SupplierNotification' }"
					v-if="checkModule({ moduleName: 'Proveedor' })"
				>
					<div class="text">
						<v-icon>mdi-handshake</v-icon>
						<div class="text__title">Proveedor</div>
						<div class="text__description"></div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Dashboard',

	methods: {
		checkModule({ moduleName = '' }) {
			let found = false;
			const activities = this.$store.state.auth.activities || [];
			if (Array.isArray(activities)) {
				found = activities.some((activity) => {
					return activity.module_name == moduleName;
				});
			}
			return found;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/dashboardHome.scss';
</style>
